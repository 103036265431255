<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="card bd-0 mg-b-20">
                    <div class="card-header bg-primary tx-white">
                        Advanced Claim Search
                    </div>
                    <div class="card-body">
    
                        <p style="color: white; font-size: 16px;">This form will search for any claims matching the search criteria you provide. Your input can be in upper or lowercase. You must justify why you are using this search facility and an audit log will be kept of your search.</p>
    
                        <p style="font-size: 16px;" class="text-warning">This facility searches for an exact match. If you type the full address in it will look for an exact match to that full address.</p>

                        <div class="row mg-b-25">
                            <div class="col-lg-4">
                              <div class="form-group">
                                <label class="form-control-label">Name:</label>
                                <input class="form-control form-control-dark" v-model="name" type="text" name="name" placeholder="Enter name">
                              </div>
                            </div>
                            <div class="col-lg-8">
                                <div class="form-group">
                                    <label class="form-control-label">Address:</label>
                                    <input class="form-control form-control-dark" v-model="address" type="text" name="address" placeholder="Enter address">
                                </div>
                            </div>
                        </div>
                        <div class="row mg-b-25">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label class="form-control-label">Reason for Search: <span class="text-danger">*</span></label>
                                    <textarea class="form-control form-control-dark" v-model="reason" rows="5" placeholder="Provide as much information as you can to support your reason for this search..."></textarea>
                                    <span :class="{'text-success': reason.length > 24, 'text-danger': reason.length < 25}">{{ reason.length }}/25 Required Characters</span>
                                </div>
                            </div>
                        </div>
    
                        <button class="btn btn-primary float-right" :disabled="searching.inProgress" @click="search">Search</button>
    
                    </div>
                </div>
            </div>
        </div>
    
        <div class="row">
            <div class="col-12">
    
                <div class="card bd-0 mg-b-20">
                    <div class="card-header bg-primary tx-white">
                        Results
                    </div>
                    <div class="card-body">

                        <loader :show="searching.inProgress" :message="searching.message"></loader>

                        <div v-if="!searching.inProgress">

                            <div v-for="result in clientsWithResults" class="card bd-0 mg-b-20">
                                <div class="card-header bg-primary tx-white">
                                    {{ result.client.name }}
                                </div>
                                <div class="card-body">
                                    <table class="table table-bordered table-dark">
                                        <thead>
                                            <tr>
                                                <th>Reference</th>
                                                <th>Status</th>
                                                <th>Name</th>
                                                <th>Address</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="claim in result.claims">
                                                <td>{{ claim.reference }}</td>
                                                <td>{{ claim.status }}</td>
                                                <td>{{ claim.name }}</td>
                                                <td>{{ [claim.address_one, claim.address_two, claim.address_three, claim.address_four, claim.postcode].join(', ') }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>  

                        </div>

                    </div>  
                </div>
    
            </div>
        </div>
    </div>
</template>

<script>
import Loader from "../../components/Loader";
import Swal from "sweetalert2";
import axios from 'axios';
import authHeader from '@/services/auth-header';

export default {
    name: 'ClaimLocator',
    components: {
        Loader
    },
    data(){
        return {
            name: '',
            address: '',
            value: '',
            reason: '',
            searching: {
                inProgress: false,
                message: '',
                results: []
            }
        }
    },
    mounted(){
        if(!this.$can('claims-search')){
            this.$router.push({name: 'dashboard'});
        }
    },
    methods: {
        search(){
            console.log("Searching for claims...");

            Swal.fire({
                title: `It may take a few minutes to search for claims. Do you want to continue?`,
                icon: "warning",
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: 'Yes',
                denyButtonText: 'No'
            })
            .then((result) => {

              console.log("Result:", result.isConfirmed);

              if(result.isConfirmed){

                this.processSearch();

              }

            });

        },
        async processSearch(){

            if(this.reason.length < 25){
                Swal.fire({
                    title: `You must provide a reason for this search. It must be at least 25 characters long.`,
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonText: 'OK'
                });
                return;
            }
            
            this.searching.inProgress = true;
            this.searching.message = "Identifying clients...";

            let clients = this.$store.state.auth.user.clients;

            this.searching.message = "Found " + clients.length + " clients. Searching for claims...";

            for await (let client of clients){

                this.searching.message = "Searching for claims for " + client.name + "...";

                try{
                    let req = {
                        client_id: client.id,
                        reason: this.reason
                    }
                    if(this.name.length > 0) req.name = this.name;
                    if(this.address.length > 0) req.address = this.address;

                    let response = await axios.post('https://server.varsanpr.com/api/claims/search?client_id=' + client.id, req, {
                        headers: authHeader()
                    });

                    if(response.data.success){

                        this.searching.results.push({
                            client: client,
                            claims: response.data.results
                        });

                    }
                }catch(err){
                    this.$error(`Failed to search claims for ${client.name}`, err);
                }

            }

            this.searching.inProgress = false;

        }
    },
    computed: {
        clientsWithResults: function(){
            return this.searching.results.filter(result => result.claims.length > 0);
        }
    }
}
</script>